.head
    display: flex
    justify-content: center
    padding-top: 96px
    padding-bottom: 16px

.list
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 16px

.embla
    display: none
    overflow: hidden
    position: relative
    width: 100%

    &Container
        display: flex
        user-select: none
        -webkit-touch-callout: none
        -webkit-tap-highlight-color: transparent
        margin-left: -16px
        flex-grow: 1

    &Slide
        position: relative
        min-width: 90%
        padding-left: 16px
        display: flex
        justify-content: center
        cursor: pointer

@media (max-width: 992px)
    .list
        grid-template-columns: repeat(2, 1fr)

@media (max-width: 570px)
    .list
        display: none

    .embla
        display: block
