.reviewsHeader
    padding: 112px 0 12px

.profitGarant
    padding-top: 69px
    display: flex
    justify-content: center

.faqIndex
    padding-top: 16px

.headFaq
    padding-bottom: 12px

.popularNav
    display: grid
    grid-template-columns: 1fr 1fr

.popularNavCruise
    display: flex

.intro
    position: relative

// .countdown
//     position: absolute
//     width: 100%
//     top: 72px

.searchCruises
    position: relative
    // background-color: #000

.newYearBanner
    position: absolute
    left: 0
    right: 0

.backgroundImage
    background-image: url(/newYear/backgroundMain.png)
    background-repeat: no-repeat
    background-position: top 28px center
    background-size: contain

.bannerWrapper
    display: flex
    margin-top: 56px
    flex-direction: column
    gap: 24px

.gift
    margin-top: 96px

.yourCity
    display: grid
    width: 100%
    grid-template-columns: 1fr 1fr 1fr

.benefits
    padding-top: 96px

.benefitsList
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 16px

.embla
    display: none

@media (max-width: 768px)
    .benefitsList
        display: none

    .embla
        display: block

    .newYearBanner
        // bottom: 555px

    .backgroundImage
        background-image: none
        background-repeat: none
        background-position: none
        background-size: none

