.partnersBanner
    margin-top: 64px

.partnerBannerMob
    margin-top: 30px

.banner
    display: flex
    justify-content: center

.embla
    display: block
    overflow: hidden
    position: relative
    width: 100%
    

    &Container
        display: flex
        user-select: none
        -webkit-touch-callout: none
        -webkit-tap-highlight-color: transparent
        margin-left: -10px
        flex-grow: 1

    &Slide
        position: relative
        min-width: 100%
        // padding-left: 20px
        // display: flex
        // justify-content: center
        cursor: pointer

// @media(max-width: 550px)
//    .embla
//        width: 344px
