.intro
    padding: 60px 0 50px
    display: flex
    flex-direction: column
    align-items: center

    &Title
        font-weight: 800
        font-size: 40px
        line-height: 48px
        color: #0071E1
        text-align: center
        margin: 0


    &Link
        display: flex
        align-items: center

        span
            margin: 0 4px 0 8px


@media (max-width: 500px)
    .intro

        &Title
            font-weight: 800
            font-size: 24px
            line-height: 30px
            color: #0071E1
            text-align: center
            margin: 0

    .introBacground
        background-image: url(/newYear/backgroundMainMob.png)
        background-repeat: no-repeat
        background-position: bottom -38px center
        background-size: cover
