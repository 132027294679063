.item
    display: block
    text-align: center
    width: 100%
    text-decoration: none
    background-color: #F3F6F9
    border-radius: 16px
    padding: 16px
    position: relative

.percent
    position: absolute
    background-color: var(--success-color)
    padding: 4px 10px 4px 10px
    border-radius: 32px
    display: flex
    justify-content: center
    align-items: center
    margin: 8px
    z-index: 2
    left: 60%

    &Left
        padding-right: 2px

.img
    border-radius: 80px
    width: 128px
    height: 128px
    margin-bottom: 16px
    position: relative
    z-index: 1

.title
    font-weight: bold
    font-size: 16px
    line-height: 24px
    max-width: 192px
    margin: 0 auto
